import ApiService from "@/core/services/ApiService";

const GET_HIERARCHICAL_COMPANIES = "company/get-hierarchical-companies/";
const UPLOAD_ORGANIZATION = "company/add-multiple-company/";

export default {
    getHierarchicalEntities(payload) {
        ApiService.setHeader();
        return ApiService.post(GET_HIERARCHICAL_COMPANIES, payload).then((response) => {
            return response.data;
        });
    },
    uploadOrganization(entities) {
        ApiService.setHeader();
        return ApiService.post(UPLOAD_ORGANIZATION, entities);
    },
};
